/* eslint-disable camelcase */
import qs from "qs";

export const isCountry = (country, localesArray) => {
  if (!country) return false;
  if (typeof country !== "string") return false;

  if (localesArray && localesArray.find((locale) => locale.code === country))
    return true;

  return false;
};

const isArray = (a) => !!a && a.length !== undefined;

export const isLanguage = (language, localesArray) => {
  if (!language) return false;
  if (typeof language !== "string") return false;

  let valid = false;

  if (!isArray(localesArray)) {
    return false;
  }

  localesArray.forEach((country) => {
    if (country.languages.find((lang) => lang.code === language)) valid = true;
  });

  return valid;
};

export const isLocale = (locale, localesArray) => {
  if (!isArray(locale) || typeof locale !== "string") return false;

  const splitLocale = locale.split(":");
  if (splitLocale.length !== 2) return false;
  // if (!isCountry(splitLocale[0])) return false;

  let valid = false;

  localesArray.forEach((country) => {
    if (splitLocale[0] === country.code) {
      if (
        country.languages.find((language) => language.code === splitLocale[1])
      )
        valid = true;
    }
  });

  return valid;
};

/**
 * @param {string} language
 */
export const convertLanguageToStandard = (language) => {
  const locale = `${language}_${language.toUpperCase()}`;
  return locale.replace("en_EN", "en_UK");
};

/**
 * @param {string} country
 */
export const getLanguagesFromCountry = (locales, country) => {
  const countryObj = locales.find((c) => c.code === country);

  return countryObj ? countryObj.languages : null;
};

/**
 * @param {string} language
 */
export const getCountryFromLanguage = (locales, language) => {
  const countryCodeInLanguage = language.split("_")[1];

  const country = locales.find((c) => c.code === countryCodeInLanguage);

  return country || null;
};

/**
 * @param {string} country
 * @param {string} language
 */
export const mergeLocale = (country, language) => {
  return `${country}:${language}`;
};

/**
 * @param {string} locale
 */
export const destructureLocale = (locale) => {
  if (!!locale && locale.length) {
    return {
      country: locale && locale.split(":")[0],
      language: locale && locale.split(":")[1],
    };
  }
  return false;
};

/**
 * @function convertToAppLocalesApi - Function that transforms query response from directus to
 * the locales object structure used in the app
 * @param {*} directusLocalesData - locale query response from directus
 */
export const convertToAppLocalesApi = (directusLocalesData) => {
  const { countries } = directusLocalesData;

  const convertedLocales = countries.data.map((country) => ({
    code: country.country_code,
    name: country.country_name,
    languages: country.spoken_languages.map((language) => ({
      code: language.languages_id.code,
      name: language.languages_id.name,
    })),
    region: {
      code: country.region.region_code,
      name: country.region.region_name,
    },
  }));

  return convertedLocales;
};

export const addCountriesToRegions = (regions, countries) =>
  regions.map((region) => ({
    ...region,
    countries: countries.filter(
      (country) => country.region.code === region.region_code
    ),
  }));

export const subCategoryHoverProductCards = (services, item) => {
  if (services) {
    return "SERVICES";
  }
  if (item?.axles?.includes("ALL_POSITIONS")) {
    return "MULTIAXLE";
  }
  if (item?.axles?.join() === "STEER") {
    return "STEER";
  }
  if (item?.axles?.join() === "DRIVE") {
    return "DRIVE";
  }
  if (item?.axles?.join() === "TRAILER") {
    return "TRAILER";
  }

  if (item?.business_unit === "AGRO") {
    return item?.business_unit;
  }
  if (item?.business_unit === "OTR") {
    return item?.business_unit;
  }

  return null;
};

// query string to object, for Articles
export const articlesUrl = (country, status, section, quantity) => {
  return qs.stringify({
    fields: "*.*.*",
    filter: {
      "country_visibility.countries_id.country_code": { in: country },
      sections: { like: section },
      status,
    },
    limit: quantity,
    sort: "-publishing_date",
  });
};

// to get translations
export const getTranslations = (text, lan) => {
  return text ? text?.find((i) => i.language_code === lan) : {};
};

export const useIsSSR = () => {
  return typeof window === "undefined";
};

const isIE = () => {
  // IE 10 and IE 11
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
};

export const useIsIE = () => {
  const isSSR = useIsSSR();
  if (!isSSR) {
    return isIE();
    // return /* @cc_on!@ */ false || !!window.document.documentMode;
  }
  return false;
};
// return rtl flag
export const arabicFont = (path, language) => {
  if (language === "ar_EG" || language === "ar_MA" || language === "ar_DZ") {
    return true;
  }
  return false;
};

export const isLtlPages = (route) => {
  const path = route?.slice(18);

  const paths = [
    "",
    "services",
    "services/[serviceId]",
    "our-world",
    "our-world/[OurWorldId]",
    "media",
    "media/news/[postId]",
    "products",
    "news/[postId]",
    "products/[productId]",
    "products/find",
    "products/dealer-locator",
    "products/brands",
    "products/brands/eu-tyre-label",
    "pfu",
    "pfu/[pfuID]",
  ];

  if (paths.includes(path)) {
    return false;
  }
  return true;
};
